.questionGroupPair {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(-45deg, #405a6b, #778d9b, #c30144, #ee7752);
  background-size: 300% 300%;
  animation: gradient 15s ease infinite;
  margin-bottom: 20px;
  margin-top: 20px;
}
.questionGroupOdd {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(-45deg, #405a6b, #778d9b, #c30144, #ee7752);
  background-size: 300% 300%;
  animation: gradientOdd 16s ease infinite;
  margin-bottom: 20px;
  margin-top: 20px;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes gradientOdd {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
