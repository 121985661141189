.disabledField div {
  padding: 0 !important;
}
.disabledField div textarea {
  padding: 12px 14px;
}
.disabledField input:disabled,
.disabledField textarea:disabled {
  cursor: not-allowed;
  background-color: #dfdfdf;
}
