.custom-flatpickr {
  height: 2.5rem;
  border-radius: 4px;
  border: 1px solid #bbb;

  padding: 0.5rem;

  color: #32546d;
  font-family: 'BelfiusNormal';

  transition: background-color 0.2s ease;
  transition: outline-color 0.2s ease;

  width: 100%;
}

.custom-flatpickr:focus {
  outline-color: #cf3369;
}
.custom-flatpickr:disabled {
  cursor: not-allowed;
  background-color: #dfdfdf;
}

.custom-checkbox:disabled {
  cursor: not-allowed;
}
